import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_recipients_editor = _resolveComponent("recipients-editor")!
  const _component_compose_form_line = _resolveComponent("compose-form-line")!
  const _component_sender_settings = _resolveComponent("sender-settings")!
  const _component_compose_audio_section = _resolveComponent("compose-audio-section")!
  const _component_compose_schedule_section = _resolveComponent("compose-schedule-section")!
  const _component_compose_footer = _resolveComponent("compose-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_compose_form_line, {
      label: "To",
      "bottom-border": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_recipients_editor)
      ]),
      _: 1
    }),
    _createVNode(_component_compose_form_line, {
      label: "From",
      "bottom-border": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_sender_settings)
      ]),
      _: 1
    }),
    _createVNode(_component_compose_form_line, {
      label: "Audio file",
      "bottom-border": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_compose_audio_section)
      ]),
      _: 1
    }),
    (_ctx.scheduleOpen)
      ? (_openBlock(), _createBlock(_component_compose_form_line, {
          key: 0,
          label: "Schedule",
          "label-line-height": 20,
          "bottom-border": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_compose_schedule_section)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_compose_footer, {
      "schedule-open": _ctx.scheduleOpen,
      "onUpdate:schedule-open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scheduleOpen) = $event)),
      "hide-preview": "",
      class: _normalizeClass({ 'px-3': _ctx.isSmMax })
    }, null, 8, ["schedule-open", "class"])
  ], 64))
}