
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ComposeHint from '@/components/shared/compose/ComposeHint.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'
import ComposeFieldWrapper from '@/components/shared/compose/ComposeFieldWrapper.vue'

export default defineComponent({
  name: 'ComposeAudioSection',
  components: { ComposeFieldWrapper, ComposeHint, TmButton },
  setup() {
    const value = ref('')
    const { openModalAudioRecordings } = useComposeOpenModal()

    return {
      value,
      openModalAudioRecordings,
    }
  },
})
